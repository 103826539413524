#root,
html,
body {
  margin: 0px;
  padding: 0px;
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.mf-table-row-selected .mf-table-cell {
  background-color: #dadada !important;
}