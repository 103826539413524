.renting-detail-description {
  .mf-descriptions-view{
    .mf-descriptions-item-container {
      align-items: center;
    }
  }
  .mf-tag.mf-tag-blue-inverse  {
    white-space: wrap;
  }
}
